import API from './Api';
import ClientsAPI from './ClientsAPI';

class GamificationPointsAPI extends API {
  constructor() {
    super('/gamification-points');
  }

  getTodayClientPoints(client_id){
    let clientsAPI = new ClientsAPI();
    return clientsAPI.getTodayClientPoints(client_id);
  }

  addPoint(point_id, client_id){
    let clientsAPI = new ClientsAPI();
    return clientsAPI.addPoint(client_id, point_id);
  }
  
  removePointsFromClient(client_id, client_point_id){
    let clientsAPI = new ClientsAPI();
    return clientsAPI.removePointsFromClient(client_id, client_point_id);
  }
  

}

export default GamificationPointsAPI;