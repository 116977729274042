<template>
  <div>
    <b-modal title="Notas e Acompanhamento" size="lg" ok-only id="dailyNotesModal" centered @shown="getTodayNotes">
      <div class="row">
        <div class="col-2 text-center">
          <img :src="client.image ? client.image.thumbnail : 'assets/imgs/avatar.png'" class="rounded-2 shadow-sm" />
          <p class="m-2">
            <b>{{ client !== undefined && client.name !== null && client.name !== "" ? client.shortname : "" }}</b>
          </p>
          <p>
            <small>{{
              client.gradelevel !== undefined && client.gradelevel !== null ? client.gradelevel.name : ""
            }}</small>
          </p>
        </div>
        <div class="col-10">

          <div class="row mb-3">
            <button type="button" class="btn btn-primary" @click="otherNotes(true)"
              v-if="!showNotes && !showEditNote">Adicionar Nota Personalizada</button>
          </div>

          <div class="quick-notes row" v-if="!showNotes && !showEditNote">
            <h4>Notas rápidas</h4>
            <b-overlay :show="isSaving">
              <div class="row">
                <button type="button" class="col-sm-5 col-md-3 btn btn-warning m-1" @click="insertQuickNote(qNote)"
                  v-for="(qNote, index) in quick_notes" :key="index">
                  <div class="icon">
                    <i v-bind:class="`fa fa-2x ${qNote.icon}`"></i>
                  </div>
                  <div class="text">
                    {{ qNote.label }}
                  </div>
                </button>
              </div>
            </b-overlay>
          </div>

          <div class="col" v-if="showNotes">
            <b-overlay :show="isSaving">
              <h4>Adicionar Nota Personalizada</h4>
              <textarea class="form-control" v-model="editingNote.message" rows="4"></textarea>
              <div class="col-12">
                <div class="form-check mt-2">
                  <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked"
                    v-model="editingNote.notify_tutors" />
                  <label class="form-check-label" for="flexCheckChecked"> Notificar Enc. Educação </label>
                </div>

                <button type="button" class="btn btn-primary mt-3" @click="saveNote()">Guardar Nota</button>&nbsp;
                <button type="button" class="btn btn-secondary mt-3" @click="otherNotes(false)"
                  v-if="showNotes">Cancelar</button>
              </div>
            </b-overlay>
          </div>

          <div class="col" v-if="showEditNote">
            <b-overlay :show="isSaving">
              <label class="mt-3">Editar Nota</label>
              <textarea class="form-control" v-model="editingNote.message" rows="3"></textarea>
              <div class="col-12">
                <div class="form-check mt-2">
                  <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked"
                    v-model="editingNote.notify_tutors" />
                  <label class="form-check-label" for="flexCheckChecked"> Notificar Enc. Educação </label>
                </div>
                <button type="button" class="btn btn-secondary mt-3" @click="cancelEditNote">Cancelar</button>&nbsp;
                <button type="button" class="btn btn-primary mt-3" @click="updateNote">Atualizar Nota</button>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-4">
        <b-overlay :show="isLoading">
          <div class="col-12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Autor</th>
                  <th>Nota</th>
                  <th>Notificar EE</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="notes.length == 0">
                  <td colspan="5" class="text-center">Ainda não existem notas diárias</td>
                </tr>

                <tr v-for="(note, index) in notes" :key="index">
                  <td><small>{{ note.created_at }}</small></td>
                  <td><small>{{ note.created_by ? note.created_by.name : "" }}</small></td>
                  <td>{{ note.message }}</td>
                  <td><i v-if="note.notify_tutors" class="fa fa-check"></i></td>
                  <td>
                    <button type="button" class="btn btn-sm" @click="editNote(note)"><i
                        class="fa fa-pencil"></i></button>
                    <button type="button" class="btn btn-sm" @click="deleteNote(note)"><i
                        class="fa fa-trash"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import QuickNotesAPI from "@/api/QuickNotesAPI";
import DailyNotesAPI from "@/api/DailyNotesAPI";

export default {
  name: "DailyNotesModal",
  data() {
    return {
      isSaving: false,
      isLoading: false,
      showNotes: false,
      showEditNote: false,
      notes: [],
      editingNote: {},
      quick_notes: [],
      quickNotesAPI: new QuickNotesAPI(),
      dailyNotesAPI: new DailyNotesAPI(),
      client: {},
    };
  },
  mounted() {
    // get quick notes definitions
    this.getQuickNotes();

    this.$root.$on("dailyNotesModal_Open", (client) => {
      this.client = {};
      this.client = client;
      this.$bvModal.show("dailyNotesModal");
    });

  },
  methods: {
    // save a new note
    saveNote() {
      this.editingNote.client_id = this.client.id;
      this.doSaveDailyNote(this.editingNote);
    },

    // get client today notes
    getTodayNotes() {
      this.isLoading = true;
      this.notes = [];

      this.dailyNotesAPI
        .getClientNotes(this.client.id)
        .then((res) => {
          this.notes = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    // toggle other notes
    otherNotes(noteMode) {
      this.showNotes = noteMode;
      this.editingNote = {};
    },

    // open for editing note
    editNote(note) {
      this.showEditNote = true;
      this.editingNote = note;
      this.showNotes = false;
    },

    // delete a note
    deleteNote(note) {
      let self = this;
      this.$confirm("Tem a certeza que quer eliminar este registo?", "Eliminar nota?", "question")
        .then(() => {
          self.doRemoveNote(note);
        });
    },

    cancelEditNote() {
      this.showEditNote = false;
    },

    doRemoveNote(note) {
      if (note.id) {
        this.isSaving = true;
        this.isLoading = true;
        this.dailyNotesAPI
          .delete(note.id)
          .then((res) => {
            if (res.status == 204) {
              this.$root.$emit("toastSuccess", "Nota eliminada corretamente");
              this.isSaving = false;
              this.isLoading = false;
              this.getTodayNotes();
            }
          })
          .catch((err) => {
            this.isSaving = false;
            this.isLoading = false;
            console.log(err);
          });
      }
      getTodayNotes();
    },

    // get quicknotes for usage
    getQuickNotes() {
      this.quickNotesAPI
        .all()
        .then((res) => {
          this.quick_notes = res.data.data;
        })
        .catch((err) => console.log(err));
    },

    // insert a daily note based on quick note
    insertQuickNote(qNote) {
      let dailyNote = {
        client_id: this.client.id,
        message: qNote.message,
        is_quick_note: true,
        notify_tutors: qNote.notify_tutors,
      };

      this.doSaveDailyNote(dailyNote);
    },

    updateNote() {
      this.dailyNotesAPI.update(this.editingNote.id, this.editingNote).then((res) => {
        if (res.status == 200) {
          this.$root.$emit("toastSuccess", "Nota atualizada corretamente");
          // close stuff
          this.showEditNote = false;
        }
      });
    },

    // save in database
    doSaveDailyNote(note) {
      this.dailyNotesAPI.store(note).then((res) => {
        if (res.status == 200) {
          this.$root.$emit("toastSuccess", "Nota inserida corretamente");
          this.getTodayNotes();
        }
      });
    },
  },
};
</script>
