import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

import Dashboard from '../views/modules/dashboard/Dashboard.vue'

import dashboardRoutes from "./dashboardRoutes";
import clientsRoutes from "./clientsRoutes";
import messageCenterRoutes from "./messageCenterRoutes";
import userRoutes from "./userRoutes";
import gamificationPointsRoutes from "./settings/GamificationPointsRoutes";
import QuickNotesRoutes from './settings/QuickNotesRoutes';


Vue.use(VueRouter)

var routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login-page" */ '../views/Login.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "login-page" */ '../views/Logout.vue'),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      group: "dashboard"
    },
  },
  ...dashboardRoutes,
  ...clientsRoutes,
  ...messageCenterRoutes,
  ...userRoutes,
  ...gamificationPointsRoutes,
  ...QuickNotesRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return;
    }
    next('/')
  } else {
    next()
  }
});

export default router;