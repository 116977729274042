import API from './Api';

class UserAPI extends API {
  constructor() {
    super('/users');
  }

  doLogin(email, password) {
    return this.axios.post(this.endpoint + '/login', {
      email, password
    });
  }

  me() {
    return this.axios.get(this.endpoint + '/me');
  }

  /**
   * Save my own profile data
   * @param { Object } user
   */
  saveMe(user) {
    return this.axios.post(this.endpoint + "/me", user);
  }
}

export default UserAPI;