export default [
  {
    path: '/gamification-points',
    name: 'Gamification Points listing',
    component: () => import(/* webpackChunkName: "gamification-points-listing" */ '../../views/modules/settings/gamification-points/GamificationPointsListing.vue'),
    meta: {
      requiresAuth: true,
      group: "settings"
    },
  },
  {
    path: '/gamification-points/:id',
    name: 'Gamification Points Edit',
    component: () => import(/* webpackChunkName: "gpoints-editing" */ '../../views/modules/settings/gamification-points/GamificationPointsEdit.vue'),
    meta: {
      requiresAuth: true,
      group: "settings"
    },
  },
];