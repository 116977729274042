import API from './Api';

class AttendanceAPI extends API {
  constructor() {
    super('/attendances');
  }

  getAllByClientId(client_id, start_date, end_date) {

    let url = `${this.endpoint}/by-client/${client_id}`;

    if (start_date !== undefined && start_date !== null) {
      url = url + `/${start_date}`;
    }

    if (end_date !== undefined && end_date !== null) {
      url = url + `/${end_date}`;
    }

    return this.axios.get(url);
  }

  getTodayByClientId(client_id) {
    let today = new Date();
    today = today.getFullYear() + "-" + (today.getMonth()+1) + "-" + today.getUTCDate()
    console.log(today);
    return this.getAllByClientId(client_id, today, today);
  }
}

export default AttendanceAPI;