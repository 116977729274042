<template>
  <div>
    <b-modal :title="titulo" size="md" @ok.prevent="save()" id="entranceControlModal" centered ok-title="Guardar"
      cancel-title="Fechar">
      <div class="row">
        <div class="col-4 text-center">
          <h4>{{ client.shortname }}</h4>
          <img :src="client.image ? client.image.thumbnail : 'assets/imgs/avatar.png'" class="rounded-2 shadow-sm" />
          <p class="mt-2">
            <small>{{ client.gradelevel ? client.gradelevel.name : "" }} </small>
          </p>
        </div>

        <div class="col mt-4">
          <b-overlay :show="isLoading">
            <div v-if="showDate">
              <label>Data</label>
              <input type="date" class="form-control" v-model="attendance.entrance_date" />
            </div>
            <label class="mt-3">Hora Entrada</label>
            <input type="time" class="form-control" step="1" min="09:00" max="20:00" required
              v-model="attendance.time_in" />
            <label class="mt-3">Hora Saída</label>
            <input type="time" class="form-control" step="1" min="09:00" max="20:00" v-model="attendance.time_out" />

            <div class="row mt-2">
              <div clasS="col-md-3" v-if="attendance.id">
                <button type="button" class="btn btn-danger btn-sm" @click="deleteAttendance">eliminar</button>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AttendanceAPI from "@/api/AttendanceAPI";

export default {
  name: "EntranceControlModal",
  props: {
    /**
     * If true, the date input will be shown
     */
    showDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      client: {},
      attendance: {
        id: null,
        time_in: null,
        time_out: null,
      },
      isLoading: true,
      attendance_id: null,
      attendanceAPI: new AttendanceAPI(),
    };
  },
  mounted() {

    this.$root.$on("EntranceControlModal_Open", (params) => {
      this.attendance = {
        id: null,
        time_in: null,
        time_out: null,
      };

      if (params.attendance_id !== undefined) {
        this.attendance.id = params.attendance_id;
      }

      this.client = {};
      this.client = params.client;
      this.getAttendance();
      this.$bvModal.show("entranceControlModal");
      
    });
  },

  methods: {

    // save and update
    save() {
      let self = this;
      this.attendance.client_id = this.client.id;
      this.$confirm("Pretende guardar o registo?", "Guardar dados?", "question").then(() => {
        this.isLoading = true;
        if (this.attendance.id !== null) {

          this.attendanceAPI
            .update(self.attendance.id, self.attendance)
            .then((res) => {
              if(res.status == 200){
                this.$toast.success('Dados atualizados com sucesso!');
              }
              self.attendance = res.data.data;
              self.isLoading = false;
              self.$bvModal.hide("entranceControlModal");
              self.$root.$emit("updateDashboard");
            })
            .catch((err) => console.log(err));
        } else {

          self.attendance.client_id = self.client.id;

          this.attendanceAPI
            .store(self.attendance)
            .then((res) => {
              if(res.status == 201){
                this.$toast.success('Registo de entrada criado corretamente!');
              }
              self.attendance = res.data.data;
              self.isLoading = false;
              self.$root.$emit("updateDashboard");
              self.$bvModal.hide("entranceControlModal");
            })
            .catch((err) => console.log(err));
        }
      });
    },

    // get attendance
    getAttendance() {

      this.isLoading = true;
      if (this.attendance.id !== null) {
        this.attendanceAPI
          .find(this.attendance.id)
          .then((res) => {
            this.attendance = res.data.data;
            this.isLoading = false;
          })
          .catch((err) => console.log(err));

      } else {

        // get the last one
        this.isLoading = false;
      }
    },

    // delete 
    deleteAttendance() {
      const self = this;
      this.$confirm('Tem a certeza que pretende eliminar esta entrada?', 'Eliminar Entrada')
        .then(() => {
          this.attendanceAPI
            .delete(self.attendance.id)
            .then((res) => {
              if (res.status === 204) {
                self.isLoading = false;
                self.$bvModal.hide("entranceControlModal");
                self.$root.$emit("updateDashboard");
                this.$toast.success('Registo eliminado corretamente.');
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })

        .catch((err) => {
          console.log(err);
        });
    }
  },
  computed: {
    titulo() {
      if(this.attendance.id){
        return 'Editar Registo de Entradas e Saídas'
      } else {
        return 'Novo Registo de Entradas e Saídas'
      }
      
    }
  }
};
</script>
