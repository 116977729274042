export default [
  {
    path: '/quick-notes',
    name: 'Quick Notes listing',
    component: () => import(/* webpackChunkName: "quicknotes-listing" */ '../../views/modules/settings/quick-notes/QuickNotesListing.vue'),
    meta: {
      requiresAuth: true,
      group: "settings"
    },
  },
  {
    path: '/quick-notes/:id',
    name: 'Quick Notes Edit',
    component: () => import(/* webpackChunkName: "quicknotes-editing" */ '../../views/modules/settings/quick-notes/QuickNotesEdit.vue'),
    meta: {
      requiresAuth: true,
      group: "settings"
    },
  },
];