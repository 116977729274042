import axios from "axios";
import store from "../store/index";
import Vue from 'vue';

class API {

  constructor(endpoint) {
    this.endpoint = endpoint;

    let headers = {};

    if (store.getters.getUserToken) {
      headers = {
        'Authorization': 'Bearer ' + store.getters.getUserToken,
        'Accept': 'application/json'
      }
    }
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
      headers
    });

    // must be authenticated
    this.axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {

      if (!error.response) {
        Vue.toasted.show("Encontrámos problemas de rede. Poderá estar sem rede ou sem acesso ao servidor...", {
          icon: 'fa-exclamation-triangle',
          action: {
            icon: 'fa-times',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          },
          type: "info",
        });
      } else if (error.response.status == 401) {

        // clear session

        Vue.$toast.warning("A sua sessão expirou! A redirecionar.... Por favor aguarde...");
        store.dispatch('logoutUser');

        setTimeout(function () {
          window.location = "/";
        }, 3000);

      } else if (error.response.status == 422) {
        console.log(error.response);
        // const firstError = Object.entries(error.response.data.errors)[0][1][0];

        // the first error
        const firstError = Object.entries(error.response.data.errors)[0][1];
        Vue.$toast.error("Ocorreu um erro: <br><b> " + firstError + "</b>");

      }

      return Promise.reject(error);
    });
  }

  /**
   * Get all results
   */
  all(additional_query) {

    let url = this.endpoint + "?perPage=" + (process.env.VUE_APP_LISTING_NUM_ITEMS || 30);

    if(additional_query !== undefined){
      url =  url + additional_query;
    }
    return this.axios.get(url);
  }

  /**
   * Get an item by Id
   * @param {any} id
   */
  find(id) {
    return this.axios.get(this.endpoint + `/${id}`);
  }

  /**
   * update an item
   * @param {any} id
   */
  update(id, data) {
    return this.axios.put(this.endpoint + `/${id}`, data);
  }

  /**
   * Create an item
   * @param {any} id
   */
  store(data) {
    return this.axios.post(this.endpoint, data);
  }

  /**
   * Delete an item
   * @param {*} id
   * @returns
   */
  delete(id) {
    return this.axios.delete(`${this.endpoint}/${id}`);
  }

  search(query) {
    return this.axios.get(`${this.endpoint}?search=${query}&perPage=100`);
  }

  /**
   * Search for data in API endpoint
   * @param {number} page - current page from pager, default is 1
   * @param {String} search - search term we are looking for
   * @param {boolean} includeInactive - should include items that are inactive?
   * @returns 
   */
  pagedSearch(page, search, includeInactive) {
    
    if (page === undefined || page == null) {
      page = 1
    }

    let url = `${this.endpoint}?&perPage=${process.env.VUE_APP_LISTING_NUM_ITEMS}&page=${page}`;

    if (search.trim().length > 0) {
      url += `&search=${search}`;
    }

    if (includeInactive === false) {
      url += '&filters[]=is_active=1';
    }

    return this.axios.get(`${url}`);
  }

  geAddresses(recipient_id) {
    return this.axios.get(`${this.endpoint}/${recipient_id}/addresses?perPage=50`);
  }

  getAxios() {
    return this.axios;
  }

}

export default API;
