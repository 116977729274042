export default [
  {
    path: '/message-center',
    name: 'Message Center listing',
    component: () => import(/* webpackChunkName: "mcenter-listing" */ '../views/modules/messageCenter/MessageCenter.vue'),
    meta: {
      requiresAuth: true,
      group: "message-center"
    },
  },
  {
    path: '/message-center/:id',
    name: 'Message Center Edit',
    component: () => import(/* webpackChunkName: "mcenter-editing" */ '../views/modules/messageCenter/MessageCenter.vue'),
    meta: {
      requiresAuth: true,
      group: "message-center"
    },
  },
];