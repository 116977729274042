<template>
  <div>
    <b-modal title="Avaliações" size="lg" ok-only id="evaluationsModal" centered @shown="getEvaluations">
      <div class="row">
        <!-- left coll -->
        <div class="col-2 text-center">
          <img :src="client.image ? client.image.thumbnail : 'assets/imgs/avatar.png'" class="rounded-2 shadow-sm" />
          <p class="m-2">
            <b>{{ client !== undefined && client.name !== null && client.name !== "" ? client.shortname : "" }}</b>
          </p>
          <p>
            <small>{{
              client.gradelevel !== undefined && client.gradelevel !== null ? client.gradelevel.name : ""
            }}</small>
          </p>
        </div>
        <!-- right col -->
        <div class="col-10">

          <div class="row mt-4 mb-4">
            <b-overlay :show="isLoading">
              <div class="col-12">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Disciplina</th>
                      <th>Ano</th>
                      <th>Data</th>
                      <th>Nota</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="evaluations.length == 0">
                      <td colspan="6" class="text-center">Ainda não existem avaliações </td>
                    </tr>

                    <tr v-for="(evaluation, index) in evaluations" :key="index">
                      <td><small>{{ evaluation.evaluation_type.name }}</small></td>
                      <td><small>{{ evaluation.school_subject ? evaluation.school_subject.name : "-" }}</small></td>
                      <td>{{ evaluation.grade_level ? evaluation.grade_level.name : "-" }}</td>
                      <td>{{ evaluation.due_date ? evaluation.due_date : '-' }}</td>
                      <td>{{ evaluation.grade ? evaluation.grade : '-' }}</td>
                      <td>
                        <button type="button" class="btn btn-sm" @click="editEvaluation(evaluation)"><i
                            class="fa fa-pencil"></i></button>
                        <button type="button" class="btn btn-sm" @click="deleteEvaluation(evaluation)"><i
                            class="fa fa-trash"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-overlay>
          </div>

          <button type="button" class="btn btn-primary" @click="addEvaluation" v-if="!showEvaluation">Adicionar
            Avalição</button>

          <evaluation-form :client.sync="client" :showEvaluation.sync="showEvaluation"
            :editingEvaluation.sync="editingEvaluation"></evaluation-form>

        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ClientsAPI from '@/api/ClientsAPI';
import EvaluationForm from './EvaluationForm.vue';

export default {
  name: "EvaluationsModal",
  components: { EvaluationForm },
  data() {
    return {
      school_subject_id: null,
      isSaving: false,
      isLoading: false,
      showEvaluation: false,
      clientsAPI: new ClientsAPI(),
      client: {},
      date_start: null,
      date_end: null,
      evaluations: [],
      editingEvaluation: {},
    };
  },
  mounted() {
    this.$root.$on("evaluationsModal_Open", (client) => {
      this.client = {};
      this.client = client;
      this.$bvModal.show("evaluationsModal");
    });

    this.$root.$on("evaluations::reload", () => {
      this.getEvaluations();
    });

    this.$root.$on('evaluationForm::close', () => {
      this.showEvaluation = false;
    });

  },
  methods: {

    addEvaluation() {
      this.showEvaluation = true;
    },

    closeAddEvaluation() {
      this.showEvaluation = false;
    },

    // delete an evaluation
    deleteEvaluation(evaluation) {
      let self = this;
      this.$confirm("Tem a certeza que quer eliminar este registo?", "Eliminar avaliação?", "question")
        .then(() => {
          self.doRemoveEvaluation(evaluation);
        });
    },

    editEvaluation(evaluation) {
      this.editingEvaluation = evaluation;
      this.showEvaluation = true;
    },

    cancelEditEvaluation() {
      this.showEvaluation = false;
    },

    doRemoveEvaluation(item) {
      if (item.id) {
        this.isSaving = true;
        this.isLoading = true;
        this.evaluationsAPI
          .delete(item.id)
          .then((res) => {
            if (res.status == 204) {
              this.$root.$emit("toastSuccess", "Avaliação eliminada corretamente");
              this.isSaving = false;
              this.isLoading = false;
              this.getTodayNotes();
            }
          })
          .catch((err) => {
            this.isSaving = false;
            this.isLoading = false;
            console.log(err);
          });
      }
      getEvaluations();
    },

    // get clients evaluations
    getEvaluations() {
      this.isLoading = true;
      this.evaluations = [];

      this.clientsAPI
        .getClientEvaluations(this.client.id, this.date_start, this.date_end)
        .then((res) => {
          this.evaluations = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>
