import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router/index';
import UserAPI from '../api/UserAPI';


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: null,
    user: null,
    login_time: null
  },

  getters: {
    getUserToken(state) {
      return state.token;
    },

    isAuthenticated(state) {
      if (state.login_time < new Date().getTime() + 86400) {
        return state.token !== null && state.user !== null;
      }

      // validate session
      const userAPI = new UserAPI();

      userAPI.me()
        .then(res => {
          if (res.status == 200) {
            return true;
          }
        })
        .catch(err => {
          console.log(err);
          return false;
        });
    },

    getUser(state) {
      return state.user;
    }
  },

  mutations: {

    loginUser(state, { user, token }) {
      state.token = token;
      state.user = user;
      state.login_time = new Date().getTime();
      router.push({ name: 'dashboard' }).catch(() => { });

    },

    logoutUser(state) {
      state.token = null;
      state.user = null;
      state.login_time = null;
      router.push('/');
    }

  },
  actions: {
    logoutUser(context) {
      context.commit('logoutUser')
    },
    loginUser(context, params) {
      context.commit('loginUser', params)
    }
  },
});