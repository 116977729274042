<template>
  <div id="dashboard">
    <div class="row">
      <h4 class="page-title">Bem vindo</h4>
      <hr />
    </div>
    <!-- alunos no espaço -->
    <div class="row">
      <div class="title-span atualmente">
        Inside
        <div class="col float-end">{{ clients_inside.length }} aluno(s)</div>
      </div>
    </div>

    <div class="row atualmente user-cards">
      <!-- student -->
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5" v-for="(client, index) in clients_inside" :key="index">
        <div class="card w-100">
          <div class="card-header font-1 student-name"><a :href="`clients/${client.id}`">{{ client.shortname }}</a>
            <span>{{
              client.gradelevel
                ?
                client.gradelevel.name : ''
            }}</span>
          </div>
          <div class="card-body text-center">
            <div><img :src="client.image ? client.image.thumbnail : 'assets/imgs/avatar.png'" class="rounded-5" /></div>
            <div class="card-text">
              <ul class="attendance-list">
                <li v-for="(attendance, idx) in client.today_attendances" :key="idx">
                  <button class="btn btn-link attendance-link" @click="showEntranceControl(client, attendance.id)"><i
                      class="fa fa-clock"></i> {{ attendance.time_in }} - {{
                        attendance.time_out && attendance.time_out
                      }} <i class="fa fa-pencil"></i></button>
                </li>
              </ul>
            </div>
            <p class="button-stripe">
              <button class="btn btn-secondary mr-5" title="Adicionar registo" @click="showEntranceControl(client)"><i
                  class="fa fa-clock"></i></button>
              <button class="btn btn-secondary" title="Adicionar nota" @click="showDayliNotesModal(client)"><i
                  class="fa fa-pencil"></i></button>
              <button class="btn btn-secondary" title="Dar pontos" @click="showGamificationPointsModal(client)"><i
                  class="fa fa-thumbs-up"></i></button>
            </p>
          </div>
        </div>
      </div>
      <!-- /student -->
    </div>
    <!-- fim alunos no espaço -->


    <!-- alunos que estiveram hoje -->
    <div class="row">
      <div class="title-span">
        Presentes hoje
        <div class="col float-end">{{ clients_leaved.length }} aluno(s)</div>
      </div>
    </div>

    <div class="row presentes user-cards">
      <!-- student -->
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5" v-for="(client, index) in clients_leaved" :key="index">
        <div class="card w-100">
          <div class="card-header font-1 student-name"><a :href="`clients/${client.id}`">{{ client.shortname }}</a>
            <span>{{
              client.gradelevel
                ?
                client.gradelevel.name : ''
            }}</span>
          </div>
          <div class="card-body text-center">
            <div><img :src="client.image ? client.image.thumbnail : 'assets/imgs/avatar.png'" class="rounded-5" /></div>
            <div class="card-text">
              <ul class="attendance-list">
                <li v-for="(attendance, idx) in client.today_attendances" :key="idx">
                  <button class="btn btn-link attendance-link" @click="showEntranceControl(client, attendance.id)"><i
                      class="fa fa-clock"></i> {{ attendance.time_in }} - {{
                        attendance.time_out && attendance.time_out
                      }} <i class="fa fa-pencil"></i></button>
                </li>
              </ul>
            </div>
            <p class="button-stripe">
              <button class="btn btn-secondary mr-5" title="Adicionar Entrada/Saída do Aluno"
                @click="showEntranceControl(client)"><i class="fa fa-clock"></i></button>
              <button class="btn btn-secondary" title="Adicionar nota" @click="showDayliNotesModal(client)"><i
                  class="fa fa-pencil"></i></button>
              <button class="btn btn-secondary" title="Dar pontos" @click="showGamificationPointsModal(client)"><i
                  class="fa fa-thumbs-up"></i></button>
            </p>
          </div>
        </div>
      </div>
      <!-- /student -->
    </div>
    <!-- fim alunos no espaço -->

    <!-- alunos que não estiveram hoje -->
    <div class="row ">
      <div class="title-span gray">
        Ausentes hoje
        <div class="col float-end">{{ clients_notSeen.length }} aluno(s)</div>
      </div>
    </div>

    <div class="row ausentes user-cards">
      <!-- student -->
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-5" v-for="(client, index) in clients_notSeen" :key="index">
        <div class="card w-100">
          <div class="card-header font-1 student-name"><a :href="`clients/${client.id}`">{{ client.shortname }}</a>
            <span>{{
              client.gradelevel
                ?
                client.gradelevel.name : ''
            }}</span>
          </div>
          <div class="card-body text-center">
            <div><img :src="client.image ? client.image.thumbnail : 'assets/imgs/avatar.png'"
                class="rounded-5 shadow-sm" /></div>
            <div class="card-text">
              <ul class="attendance-list">
                <li v-for="(attendance, idx) in client.today_attendances" :key="idx">
                  <button class="btn btn-link attendance-link" @click="showEntranceControl(client, attendance.id)"><i
                      class="fa fa-clock"></i> {{ attendance.time_in }} - {{
                        attendance.time_out && attendance.time_out
                      }} <i class="fa fa-pencil"></i></button>
                </li>
              </ul>
            </div>
            <p class="button-stripe">
              <button class="btn btn-secondary mr-5" title="Registar Saída do Aluno"
                @click="showEntranceControl(client)"><i class="fa fa-clock"></i></button>
              <button class="btn btn-secondary" title="Adicionar nota" @click="showDayliNotesModal(client)"><i
                  class="fa fa-pencil"></i></button>
              <button class="btn btn-secondary" title="Dar pontos" @click="showGamificationPointsModal(client)"><i
                  class="fa fa-thumbs-up"></i></button>
              <button class="btn btn-secondary" title="Avaliações" @click="showEvaluationsModal(client)"><i
                  class="fa fa-gauge-high"></i></button>
            </p>
          </div>
        </div>
      </div>
      <!-- /student -->
    </div>

    <div class="pull-top">
      <button class="btn btn-outline-primary" @click="scrollToTop"><i
          class="fa fa-2x fa-chevron-circle-up"></i></button>
    </div>

    <!-- fim alunos no espaço -->
    <entrance-control-modal></entrance-control-modal>
    <daily-notes-modal></daily-notes-modal>
    <gamification-points-modal></gamification-points-modal>
    <evaluations-modal></evaluations-modal>
  </div>
</template>

<script>
import EntranceControlModal from "../../../components/modules/dashboard/EntranceControlModal.vue";
import DailyNotesModal from "../../../components/modules/dashboard/DailyNotesModal.vue";
import ClientsAPI from "../../../api/ClientsAPI";
import GamificationPointsModal from "@/components/modules/dashboard/GamificationPointsModal.vue";
import EvaluationsModal from "@/components/modules/clients/EvaluationsModal.vue";

export default {
  watch: {
  },
  name: "Dashboard",
  components: { EntranceControlModal, DailyNotesModal, GamificationPointsModal, EvaluationsModal },
  data() {
    return {
      clients_inside: [],
      clients_leaved: [],
      clients_notSeen: [],
      selectedClient: {},
      clientsAPI: new ClientsAPI(),
    }
  },
  methods: {
    // modal controlo de entradas
    showEntranceControl(client, attendance_id) {
      this.$root.$emit("EntranceControlModal_Open", { client: client, attendance_id: attendance_id });
    },
    // modal notas diárias
    showDayliNotesModal(client) {
      this.$root.$emit("dailyNotesModal_Open", client);
    },
    // modal pontos
    showGamificationPointsModal(client) {
      this.$root.$emit("gamificationPointsModal_Open", client);
    },
    // modal evaluations
    showEvaluationsModal(client) {
      this.$root.$emit("evaluationsModal_Open", client);
    },
    // start up of the dashboard
    getClientsData() {
      // inside
      this.clientsAPI.getClientsInside()
        .then(res => {
          this.clients_inside = res.data.data;
        }).
        catch(err => console.log(err));
      // leaved
      this.clientsAPI.getClientsLeaved()
        .then(res => {
          this.clients_leaved = res.data.data;
        }).
        catch(err => console.log(err));
      // inside
      this.clientsAPI.getClientsNotSeen()
        .then(res => {
          this.clients_notSeen = res.data.data;
        }).
        catch(err => console.log(err));
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  mounted() {
    this.getClientsData();

    this.$root.$on('updateDashboard', () => {
      this.getClientsData();
    })
  },
};
</script>
