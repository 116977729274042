<template>
    <div class="col box no-border" v-if="showEvaluation">
        <b-overlay :show="isSaving">
            <h4>{{ editingEvaluation.id ? 'Editar' : 'Adicionar' }} Avaliação</h4>

            <hr />
            <h5 v-if="client"><span class="primary-color"> {{ client.shortname }}</span> - {{
                client.gradelevel && client.gradelevel.name
            }}</h5>
            <hr />
            <div class="row">
                <div class="col-md-6">
                    <label>Tipo de Avaliação</label>
                    <v-select :options="evaluationTypes" :reduce="option => option.id" label="name"
                        v-model="editingEvaluation.evaluation_type_id"></v-select>
                </div>
                <div class="col-md-6">
                    <label>Disciplina</label>
                    <v-select :options="schoolsubjects" :reduce="option => option.id" label="name"
                        v-model="editingEvaluation.school_subject_id"></v-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Data</label>
                    <input class="form-control" type="date" v-model="editingEvaluation.due_date" />
                </div>
                <div class="col-md-6">
                    <label>Ano</label>
                    <v-select :options="gradeLevels" :reduce="option => option.id" label="name"
                        v-model="editingEvaluation.gradelevel_id"></v-select>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label>Nota Obtida</label>
                    <input clasS="form-control" type="number" step="0.01" v-model="editingEvaluation.grade" />
                </div>
            </div>
            <div v-if="visibleButtons">
                <hr />
                <button type="button" class="btn btn-secondary mt-3" @click="closeAddEvaluation">Cancelar</button>&nbsp;
                <button type="button" class="btn btn-primary mt-3" @click="saveEvaluation">{{
                    editingEvaluation.id ?
                        'Atualizar' : 'Guardar'
                }}
                    Avaliação</button>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import SchoolsubjectAPI from '@/api/SchoolsubjectAPI';
import GradeLevelsAPI from '@/api/GradeLevelsAPI';
import EvaluationsAPI from '@/api/EvaluationsAPI';
import EvaluationTypesAPI from '@/api/EvaluationTypesAPI';
export default {
    props: {
        showEvaluation: {
            type: Boolean,
            default: false,
        },
        client: {
            default: {}
        },
        editingEvaluation: {
            default: {}
        },
        visibleButtons: {
            default: true
        }
    },
    mounted() {
        this.getData();

        if (this.client.gradelevel && this.editingEvaluation.id == null) {
            this.editingEvaluation.gradelevel_id = this.client.gradelevel.id;
        }
        if (this.client.id) {
            this.editingEvaluation.client_id = this.client.id;
        }

        this.$root.$on('EvaluationForm::save', function () {
            debugger;
            this.saveEvaluation();
        });
    },
    data() {
        return {
            evaluationTypes: [],
            gradeLevels: [],
            schoolsubjects: [],
            isSaving: false,
            schoolsubjectAPI: new SchoolsubjectAPI(),
            gradeLevelsAPI: new GradeLevelsAPI(),
            evaluationsAPI: new EvaluationsAPI(),
            evaluationTypesAPI: new EvaluationTypesAPI(),
        }
    },
    name: 'EvaluationForm',

    methods: {

        getData() {

            this.schoolsubjectAPI.all()
                .then((res) => {
                    if (res.status == 200) {
                        this.schoolsubjects = res.data.data;
                    }
                })
                .catch(err => { console.log(err) });

            this.gradeLevelsAPI.all()
                .then(res => {
                    if (res.status == 200) {
                        this.gradeLevels = res.data.data;
                    }
                })
                .catch(err => { console.log(err) });

            this.evaluationTypesAPI.all()
                .then(res => {
                    if (res.status == 200) {
                        this.evaluationTypes = res.data.data
                    }
                })
                .catch(err => { console.log(err) });
        },

        closeAddEvaluation() {
            this.$root.$emit('evaluationForm::close');
        },

        // save a new evaluation
        saveEvaluation() {
            this.editingEvaluation.client_id = this.client.id;
            if (this.editingEvaluation.id == undefined || this.editingEvaluation.id == null) {
                this.doSaveEvaluation(this.editingEvaluation);
            } else {
                this.updateEvaluation();
            }
        },

        // updated editing evaluation
        updateEvaluation() {
            this.evaluationsAPI.update(this.editingEvaluation.id, this.editingEvaluation).then((res) => {
                if (res.status == 200) {
                    this.$toast.success("Avaliação atualizada corretamente", "toastSuccess");
                    // close stuff
                    this.$root.$emit('evaluationForm::close');
                    this.$root.$emit('evaluations::reload');
                }
            });
        },

        // save in database
        doSaveEvaluation(evaluation) {
            this.evaluationsAPI.store(evaluation).then((res) => {
                if (res.status == 200) {
                    this.$toast.success("Avaliação inserida corretamente", "toastSuccess");
                    this.editingEvaluation = {};
                    this.$root.$emit('evaluations::reload');
                    this.$root.$emit('evaluationForm::close');
                }
            });
        },

    },
}
</script>
