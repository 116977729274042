<template>
  <div>
    <b-modal title="Pontos" size="lg" ok-only id="gamificationPointsModal" centered @shown="getTodayPoints">
      <div class="row">
        <div class="col-2 text-center">
          <img :src="client.image ? client.image.thumbnail : 'assets/imgs/avatar.png'" class="rounded-2 shadow-sm" />
          <p class="m-2">
            <b>{{ client.shortname !== undefined && client.shortname !== null ? client.shortname : "" }}</b>
          </p>
          <p>
            <small>{{
              client.gradelevel !== undefined && client.gradelevel !== null ? client.gradelevel.name : ""
            }}</small>
          </p>
        </div>

        <div class="col">
          <div class="quick-notes row">
            <p class="text-capitalize">Pontos Positivos</p>
            <b-overlay :show="isSaving">

              <button type="button" class="col-sm-5 col-md-3 btn btn-success m-3" @click="addPoint(gPoint)"
                v-for="(gPoint, index) in positivePoints" :key="index">
                <div class="icon">
                  <i v-bind:class="`fa fa-2x ${gPoint.icon}`"></i>
                </div>
                <div class="text">
                  {{ gPoint.label }}<br>(+ {{ gPoint.points }})
                </div>
              </button>

            </b-overlay>




            <p class="text-capitalize">Pontos Negativos</p>
            <b-overlay :show="isSaving">
              <button type="button" class="col-sm-5 col-md-3 btn btn-danger m-3" @click="addPoint(gPoint)"
                v-for="(gPoint, index) in negativePoints" :key="index">
                <div class="icon">
                  <i v-bind:class="`fa fa-2x ${gPoint.icon}`"></i>
                </div>
                <div class="text">
                  {{ gPoint.label }}<br>(+ {{ gPoint.points }})
                </div>
              </button>
            </b-overlay>


          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-4">
        <b-overlay :show="isLoading">
          <div class="col-12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Descrição</th>
                  <th>Pontos</th>
                  <th>Autor</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="points.length == 0">
                  <td colspan="5" class="text-center">Ainda não existem pontos hoje</td>
                </tr>
                <tr v-for="(point, index) in points" :key="index">
                  <td class="col-2"><small>{{ point.created_at }}</small></td>
                  <td class="col-2"><small>{{ point.gamification_point.label }}</small></td>
                  <td class="text-center">{{ point.gamification_point.points }}</td>
                  <td><small>{{ point.employee_id ? point.employee.name : "" }}</small></td>
                  <td>
                    <button type="button" class="btn btn-sm" @click="deletePoint(point)"><i
                        class="fa fa-trash"></i></button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="5">
                    <div class="row text-center">
                      <div class="col m-2">
                        <b>Pontos Positivos</b> <br>
                        {{ points_data.total_positive }}
                      </div>
                      <div class="col m-2">
                        <b>Pontos Negativos</b> <br>
                        {{ Math.abs(points_data.total_negative) }}
                      </div>
                      <div class="col m-2">
                        <b>Saldo</b> <br>
                        {{ points_data.total_points }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import GamificationPointsAPI from "@/api/GamificationPointsAPI";

export default {
  name: "GamificationPointsModal",
  data() {
    return {
      isSaving: false,
      isLoading: false,
      points: [],
      gamification_points: [],
      points_data: [],
      gamificationPointsAPI: new GamificationPointsAPI(),
      client: {},
    };
  },
  mounted() {
    // get quick notes definitions
    this.getGamificationPoints();
    this.getTodayPoints();

    this.$root.$on("gamificationPointsModal_Open", (client) => {
      console.log(client);
      this.client = {};
      this.client = client;
      this.$bvModal.show("gamificationPointsModal");
    });

  },

  methods: {
    // get client today notes
    getTodayPoints() {
      this.isLoading = true;
      this.notes = [];

      this.gamificationPointsAPI
        .getTodayClientPoints(this.client.id)
        .then((res) => {
          this.points_data = res.data.data;
          this.points = res.data.data.points;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },

    // delete a note
    deletePoint(point) {
      let self = this;
      this.$confirm("Eliminar os pontos atribuídos?", "Eliminar pontos?", "question")
        .then(() => {
          self.doRemovePoints(point);
        });
    },

    // get gamification points for usage
    getGamificationPoints() {
      this.gamificationPointsAPI
        .all()
        .then((res) => {
          this.gamification_points = res.data.data;
        })
        .catch((err) => console.log(err));
    },

    // adds points
    addPoint(point) {

      this.gamificationPointsAPI
        .addPoint(point.id, this.client.id)
        .then(res => {
          if (res.status == 204) {
            this.$toast.success("Pontos adicionados!")
            this.getTodayPoints();
          }
        }).
        catch(err => {
          this.$toast.error('Ocorreu um erro...');
          console.log(err);
        });
    },

    // remove points
    doRemovePoints(point) {
      let self = this;
      this.gamificationPointsAPI.removePointsFromClient(this.client.id, point.id)
        .then(res => {
          if (res.status == 204) { }
          self.getTodayPoints();
        })
        .catch(err => console.log(err));
    }
  },
  computed: {
    positivePoints() {
      return this.gamification_points.filter(elem => elem.points > 0);
    },
    negativePoints() {
      return this.gamification_points.filter(elem => elem.points < 0);
    },
  }
};
</script>
