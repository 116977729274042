export default [
  {
    path: '/clients',
    name: 'Clients listing',
    component: () => import(/* webpackChunkName: "clients-listing" */ '../views/modules/clients/Clients.vue'),
    meta: {
      requiresAuth: true,
      group: "clients"
    },
  },
  {
    path: '/clients/daily-notes-points',
    name: 'Client Daily Notes',
    component: () => import(/* webpackChunkName: "client-dailnotes" */ '../views/modules/clients/DailyNotesAndPoints.vue'),
    meta: {
      requiresAuth: true,
      group: "clients"
    },
  },
  {
    path: '/clients/evaluations',
    name: 'Evaluations',
    component: () => import(/* webpackChunkName: "clients-evaluation-listing" */ '../views/modules/clients/EvaluationsListing.vue'),
    meta: {
      requiresAuth: true,
      group: "clients"
    },
  },
  {
    path: '/clients/:id',
    name: 'Client Edit',
    component: () => import(/* webpackChunkName: "client-editing" */ '../views/modules/clients/ClientEdit.vue'),
    meta: {
      requiresAuth: true,
      group: "clients"
    },
  },


];