<template>
    <span>{{ formated }}</span>
  </template>
  <script>
  export default {
    name: "DateFormat",
    props: ["date", "showTime"],
    methods: {
      getDate() {
        if (this.showTime == false) {
          return new Date(this.date).toLocaleDateString("pt-pt", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
        } else if (this.showTime == undefined || this.showTime == true) {
          return new Date(this.date).toLocaleDateString("pt-pt", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
          });
        }
      }
    },
    computed: {
      formated() {
        return this.getDate();
      }
    }
  };
  </script>