export default [
  {
    path: '/dashboard',
    name: 'Dashboard Index',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/modules/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      group: "dashboard"
    },
  },
];