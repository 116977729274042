import API from "./Api";

class ClientsAPI extends API {
  constructor() {
    super("/clients");
  }

  getClientsInside() {
    return this.axios.get("/today-clients/inside");
  }

  getClientsLeaved() {
    return this.axios.get("/today-clients/leaved");
  }

  getClientsNotSeen() {
    return this.axios.get("/today-clients/not-seen");
  }

  getClientsForListing(page, search, includeInactive) {
    let url = `${this.endpoint}?fields=erp_code,id,name,phone,gradelevel_id,school_id,updated_at&relations=gradelevel,schoolyears,school&perPage=${process.env.VUE_APP_LISTING_NUM_ITEMS}&page=${page}`;
    if (search.trim().length > 0) {
      url += `&search=${search}`;
    }
    if (includeInactive === false) {
      url += "&filters[]=is_active=1";
    }

    return this.axios.get(`${url}`);
  }

  getClientDailyNotesAndPoints(search, currentDate) {
    let url = `${this.endpoint}/daily-notes-points`;

    if (currentDate !== null && currentDate !== undefined) {
      url += `?day=${currentDate}`;
    }
    if (search.trim().length > 0) {
      url += `&search=${search}`;
    }
    return this.axios.get(`${url}`);
  }

  getClientServices(client_id, schoolyear_id) {
    let filter = "";
    if (parseInt(schoolyear_id)) {
      filter = `&filters[]=schoolyear_id=${schoolyear_id}`;
    }
    return this.axios.get(
      `${this.endpoint}/${client_id}/services?relations=service,schoolyear,schoolsubject,gradelevel${filter}`
    );
  }

  /**
   * Add service to client
   * @param {int} client_id
   * @param {object} data
   * @returns
   */
  addClientService(client_id, data) {
    return this.axios.post(`${this.endpoint}/${client_id}/services`, data);
  }

  getServiceById(client_id, service_id) {
    return this.axios.get(
      `${this.endpoint}/${client_id}/services/${service_id}`
    );
  }

  updateServiceById(client_id, service_id, data) {
    return this.axios.put(
      `${this.endpoint}/${client_id}/services/${service_id}`,
      data
    );
  }

  deleteServiceById(client_id, service_id, data) {
    return this.axios.delete(
      `${this.endpoint}/${client_id}/services/${service_id}`
    );
  }

  // gamification
  addPoint(client_id, point_id) {
    return this.axios.post(
      `${this.endpoint}/${client_id}/gamification-points/${point_id}`
    );
  }

  getTodayClientPoints(client_id) {
    return this.axios.get(`${this.endpoint}/${client_id}/gamification-points`);
  }

  removePointsFromClient(client_id, client_point_id) {
    return this.axios.delete(
      `${this.endpoint}/${client_id}/gamification-points/${client_point_id}`
    );
  }

  getClientEvaluations(client_id, params) {
    if (params) {
      return this.axios.get(
        `${this.endpoint}/${client_id}/evaluations?${params.toString()}`
      );
    }
    return this.axios.get(`${this.endpoint}/${client_id}/evaluations`);
  }

  /**
   * Get Active Clients
   */
  getActive(perPage) {
    if (perPage == undefined || perPage == null) {
      perPage = process.env.VUE_APP_LISTING_NUM_ITEMS;
    }

    return this.axios.get(
      `${this.endpoint}?fields=id,name,shortname,gradelevel_id&relations=gradelevel,schoolyears&filters[]=is_active=1&perPage=${perPage}`
    );
  }

  getReport(client_id, start_date, end_date) {
    return this.axios.get(
      `${this.endpoint}/${client_id}/reports/comments-attendances?date_start=${start_date}&date_end=${end_date}`
    );
  }
}

export default ClientsAPI;
