import API from './Api';

class DailyNotesAPI extends API {
  constructor() {
    super('/daily-notes');
  }

  getClientNotes(client_id){
    return this.axios.get(`${this.endpoint}/today/${client_id}`)
  }

}

export default DailyNotesAPI;