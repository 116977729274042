export default [
  {
    path: '/users',
    name: 'Users listing',
    component: () => import(/* webpackChunkName: "users-listing" */ '../views/modules/users/UsersListing.vue'),
    meta: {
      requiresAuth: true,
      group: "settings"
    },
  },
  {
    path: '/profile',
    name: 'Profile page',
    component: () => import(/* webpackChunkName: "profile-page" */ '../views/modules/users/ProfilePage.vue'),
    meta: {
      requiresAuth: true,
      group: "settings"
    },
  },
  {
    path: '/users/:id',
    name: 'User edit',
    component: () => import(/* webpackChunkName: "profile-page" */ '../views/modules/users/UserEdit.vue'),
    meta: {
      requiresAuth: true,
      group: "settings"
    },
  },
];