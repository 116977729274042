<template>
    <div></div>
</template>

<script>
export default {
    name:'BaseComponent',
    mounted() {
        this.$root.$on('toastSuccess', function(message){
            this.$toast.success(message);
        });

        this.$root.$on('toastInfo', function(message){
            this.$toast.info(message);
        });

        this.$root.$on('toastError', function(message){
            this.$toast.error(message);
        });
    },
};
</script>
