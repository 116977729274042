import API from './Api';

class EvaluationsAPI extends API {
  constructor() {
    super('/evaluations');
  }

  pagedSearch(page, params) {

    if (page === undefined || page == null) {
      params.append('page', 1);
    }

    const perPage = process.env.VUE_APP_LISTING_NUM_ITEMS;
    params.append('perPage', perPage);
    let url = `${this.endpoint}/search?${params.toString()}`;

    return this.axios.get(`${url}`);
  }

}

export default EvaluationsAPI;