<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark main-menu" v-if="isAuthenticated">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-1 pt-3 text-white min-vh-100">
          <a href="/dashboard"
            class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <span class="fs-5 d-sm-inline">[br]<span class="d-none d-sm-inline"> brains
                <br><span class="text-gray-50 text-micro">{{ version }}</span>
              </span></span>
          </a>
          <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="menu">
            <li class="nav-item">
              <a href="/dashboard"
                v-bind:class="{ 'nav-link align-middle px-1 text-white': true, active: isRouteGroup('dashboard') }">
                <i class="fa fa-house"></i> <span class="ms-1 d-none d-sm-inline">Dashboard</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="/message-center"
                v-bind:class="{ 'nav-link align-middle px-1 text-white': true, active: isRouteGroup('message-center') }">
                <i class="fa fa-plane"></i> <span class="ms-1 d-none d-sm-inline">Message Center</span>
              </a>
            </li>
            <li>
              <a href="#submenu_clients" data-bs-toggle="collapse"
                v-bind:class="{ 'nav-link px-2 align-middle text-white': true, active: isRouteGroup('clients') }">
                <i class="fa fa-user-graduate"></i> <span class="ms-1 d-none d-sm-inline">Alunos</span></a>
              <ul :class="{ ' nav flex-column ms-1 p-1': true, 'collapse': !isRouteGroup('clients') }"
                id="submenu_clients" data-bs-parent="#menu">
                <li class="w-100">
                  <a href="/clients" v-bind:class="{ 'nav-link px-2': true, active: isCurrentRoute('/clients') }"> <span
                      class="d-none d-sm-inline">Listagem</span></a>
                </li>
                <li>
                  <a href="/clients/daily-notes-points"
                    v-bind:class="{ 'nav-link px-2': true, active: isCurrentRoute('/clients/daily-notes-points') }"><span
                      class="d-none d-sm-inline">Acompanhamento + Pontos</span></a>
                </li>
                <li>
                  <a href="/clients/evaluations"
                    v-bind:class="{ 'nav-link px-2': true, active: isCurrentRoute('/clients/evaluations') }"><span
                      class="d-none d-sm-inline">Avaliações</span></a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#submenu2" data-bs-toggle="collapse"
                v-bind:class="{ 'nav-link px-2 align-middle text-white': true, active: isRouteGroup('settings') }">
                <i class="fa fa-cogs"></i> <span class="ms-1 d-none d-sm-inline">Definições</span></a>
              <ul :class="{ ' nav flex-column ms-1 p-1': true, 'collapse': !isRouteGroup('settings') }" id="submenu2"
                data-bs-parent="#menu">
                <li class="w-100">
                  <a href="#" v-bind:class="{ 'nav-link px-2': true, active: isCurrentRoute('/settings/schools') }">
                    <i class="fa fa-school"></i> <span class="d-none d-sm-inline">Escolas</span> </a>
                </li>
                <li>
                  <a href="#"
                    v-bind:class="{ 'nav-link px-2': true, active: isCurrentRoute('/settings/grade-levels') }"><i class="fa fa-graduation-cap"></i> <span
                      class="d-none d-sm-inline">Anos Letivos</span></a>
                </li>
                <li>
                  <a href="/gamification-points"
                    v-bind:class="{ 'nav-link px-2': true, active: isCurrentRoute('/gamification-points') }"
                    title="Pontos"><i class="fa fa-sort-numeric-up"></i> <span class="d-none d-sm-inline">Pontos</span></a>
                </li>
                <li>
                  <a href="/quick-notes" :class="{ 'nav-link px-2': true, active: isCurrentRoute('/quick-notes') }"
                    title="Pontos">
                    <i class="fa fa-sticky-note"></i> <span class="d-none d-sm-inline">Notas Rápidas</span></a>
                </li>
                <li>
                  <a href="/users" :class="{ 'nav-link px-2': true, active: isCurrentRoute('/users') }"
                    title="Utilizadores"> <i
                      class="fa fa-users"></i> <span class="d-none d-sm-inline">Utilizadores</span></a>
                </li>
              </ul>
            </li>
            <!--<li>
              <a href="#submenu3" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                <i class="fs-4 bi-grid"></i> <span class="ms-1 d-none d-sm-inline">Products</span> </a>
              <ul class="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                <li class="w-100">
                  <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 1</a>
                </li>
                <li>
                  <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 2</a>
                </li>
                <li>
                  <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 3</a>
                </li>
                <li>
                  <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Product</span> 4</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#" class="nav-link px-0 align-middle">
                <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Customers</span> </a>
            </li> -->
          </ul>
          <hr>
          <div class="dropdown pb-4 sticky bottom">
            <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
              id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="/assets/imgs/avatar.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
              <span class="d-none d-sm-inline mx-1">{{ user.name }}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
              <!--<li><a class="dropdown-item" href="#">New project...</a></li>
              <li><a class="dropdown-item" href="#">Settings</a></li>-->
              <li><a class="dropdown-item" href="/profile">A minha conta</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#" @click="doLogout">Sair</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col p-4">
        <router-view></router-view>
      </div>
    </div>
  </div>


</template>
<script>
import { mapGetters } from "vuex";
import MainNav from "./components/core/base-ui/MainNav.vue";

export default {
  methods: {
    doLogout() {
      this.$store.dispatch('logoutUser');
    },
    isRouteGroup(groupName) {
      return this.$route.meta.group === groupName;
    },
    isCurrentRoute(route) {
      return this.$route.path === route;
    }
  },
  data() {
    return {
      version: ''
    }
  },
  mounted() {
    this.version = process.env.VUE_APP_VERSION;
  },
  components: { MainNav },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isAuthenticated: 'isAuthenticated'
    })
  }
};  
</script>
<style scoped>
.navbar-brand {
  color: #5b0035;
}
</style>