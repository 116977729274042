<template>
  <div class="w3-sidebar w3-bar-block w3-collapse w3-card w3-animate-left" style="width:200px;" id="mySidebar">
    <button class="w3-bar-item w3-button w3-large w3-hide-large" onclick="w3_close()">Close &times;</button>
    <a href="#" class="w3-bar-item w3-button">Link 1</a>
    <a href="#" class="w3-bar-item w3-button">Link 2</a>
    <a href="#" class="w3-bar-item w3-button">Link 3</a>


    <!--
  <aside class="main-menu flex-grow-sm-1 flex-shrink-1 flex-grow-0 sticky-top pb-sm-0 pb-3" v-if="isAuthenticated">
    <div class="bg-light border rounded-1 p-1 h-100 sticky-top">
      <ul class="list-unstyled ps-0">
        <li class="mb-1">
          <router-link to="/dashboard" tag="button"
            class="btn btn-toggle d-inline-flex align-items-center rounded border-0">Dashboard</router-link>
        </li>
        <li class="mb-1">
          <router-link to="/clients" tag="button"
            class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed">Alunos</router-link>
        </li>
        <li class="mb-1">
          <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
            data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">Message Center</button>
          <div class="collapse" id="dashboard-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li><a href="/message-center" class="link-dark d-inline-flex text-decoration-none rounded">Mensagens</a>
              </li>
            </ul>
          </div>
        </li>
        <li class="mb-1">
          <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed" data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="false">Orders</button>
          <div class="collapse" id="orders-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded">New</a></li>
              <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded">Processed</a></li>
              <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded">Shipped</a></li>
              <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded">Returned</a></li>
            </ul>
          </div>
        </li> --x>
        <li class="border-top my-3"></li>
        <li class="mb-1">
          <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
            data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">Definições</button>
          <div class="collapse ml-2" id="account-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded">Escolas</a></li>
              <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded">Anos Letivos</a></li>
              <li><a href="#" class="link-dark d-inline-flex text-decoration-none rounded">Serviços</a></li>
              <li><a href="/users" class="link-dark d-inline-flex text-decoration-none rounded">Utilizadores</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>>
    <base-component></base-component>
  </aside> -->
    <base-component></base-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseComponent from './BaseComponent.vue';
export default {
  components: { BaseComponent },
  name: "MainNav",
  computed: {
    ...mapGetters(["isAuthenticated"]),
  }
};
</script>
